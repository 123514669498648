import { template as template_ebca54ec8be64027855d540eb2919da9 } from "@ember/template-compiler";
const WelcomeHeader = template_ebca54ec8be64027855d540eb2919da9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
