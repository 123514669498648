import { template as template_69bb5295ff0e4d5393443ae41d22fa32 } from "@ember/template-compiler";
const FKLabel = template_69bb5295ff0e4d5393443ae41d22fa32(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
