import { template as template_7b0173976b24419cb971eda9e58e6048 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7b0173976b24419cb971eda9e58e6048(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
